import React from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import useDemo from '@/hooks/useDemo'

import Button from '@/components/Button'

const LandingDemoVersion = () => {
  const { setIsDemo } = useDemo()

  return (
    <div
      className={clsx(
        'flex justify-between gap-4 overflow-hidden rounded-x-3xl border border-slate-300 tablet:gap-6',
        'bg-primary-600 max-tablet:flex-col max-tablet:items-center max-tablet:px-[30px] max-tablet:py-6',
        'tablet:flex-row tablet:items-center'
      )}
    >
      <div className="flex flex-col gap-4 max-tablet:text-center tablet:gap-5 tablet:p-[30px]">
        <span className="text-2xl font-medium text-white tablet:text-3xl">
          <FormattedMessage
            {...{
              id: 'landing.demo.title',
              defaultMessage: 'Посмотрите, как работает {br} облачный майнинг',
              values: { br: <br /> }
            }}
          />
        </span>
        <span className="text-x-base text-white">
          <FormattedMessage
            {...{
              id: 'landing.demo.subtitle',
              defaultMessage:
                'Для вас мы создали демо-кабинет, в котором доступен весь функционал.'
            }}
          />
        </span>
      </div>
      <Button
        className="min-h-10 max-w-max bg-white !px-7 !py-1.5 hover:!bg-white tablet:mr-6 tablet:min-h-11 tablet:w-1/2 desktop:mr-16"
        onClick={() => setIsDemo(true)}
        variant="secondary"
      >
        <FormattedMessage
          {...{
            id: 'button.demo.login',
            defaultMessage: 'Войти в демо-кабинет'
          }}
        />
      </Button>
    </div>
  )
}

export default LandingDemoVersion
