import Image from 'next/image'

import { FormattedMessage } from 'react-intl'

const LandingChart = () => {
  return (
    <div className="space-y-8 tablet:space-y-14 desktop:space-y-20">
      <div className="relative mx-auto w-full max-w-[1040px]">
        <div className="tablet:absolute tablet:left-0 tablet:top-0">
          <h3 className="mb-6 max-w-[292px] text-2xl font-medium leading-7 tablet:max-w-[545px]  tablet:text-3xl tablet:leading-9">
            <FormattedMessage
              id="landing.chartInfo.title"
              defaultMessage="Каждые 4 года у вас есть возможность заработать 
              на курсе биткоина"
            />
          </h3>
          <p className="max-w-[292px] text-x-base leading-x-base tablet:max-w-[310px] desktop:max-w-[492px]">
            <FormattedMessage
              defaultMessage="Несмотря на волатильность криптовалюты, в среднем она показывает стабильный рост. В 2024-ом ожидается очередной рост курса"
              id="landing.chartInfo.text"
            />
          </p>
        </div>
        <div className="no-scrollbar -mx-3 rotate-180 overflow-hidden overflow-x-auto px-3 tablet:mx-0 tablet:px-0">
          <div className="flex w-[706px] rotate-180 flex-col tablet:w-full">
            <div className="mb-4 h-[198px] tablet:mb-7 tablet:h-auto tablet:w-full">
              <Image
                className="block h-full w-auto tablet:hidden"
                alt="chart"
                height={138}
                priority
                src="/images/chart-sm.svg"
                width={241}
              />
              <Image
                className="hidden h-auto w-full tablet:block desktop:hidden"
                alt="Chart"
                height={138}
                priority
                src="/images/chart-md.svg"
                width={241}
              />
              <Image
                className="hidden h-auto w-full desktop:block"
                alt="Chart"
                height={138}
                priority
                src="/images/chart-lg.svg"
                width={241}
              />
            </div>
            <div className="flex w-auto justify-between gap-10">
              <div className="flex min-w-[82px] flex-none flex-col gap-1 text-right desktop:min-w-[127px]">
                <p className="leading-x-xs text-x-sm tablet:text-x-base tablet:leading-x-base desktop:text-x-md desktop:leading-x-md">
                  2012
                </p>
                <h5 className="text-x-md font-medium leading-x-md tablet:text-xl tablet:leading-xl desktop:text-2xl desktop:leading-7">
                  30 000%
                </h5>
              </div>
              <div className="fxlex min-w-[82px] flex-none flex-col gap-1 text-right desktop:min-w-[127px]">
                <p className="leading-x-xs text-x-sm tablet:text-x-base tablet:leading-x-base desktop:text-x-md desktop:leading-x-md">
                  2016
                </p>
                <h5 className="text-x-md font-medium leading-x-md tablet:text-xl tablet:leading-xl desktop:text-2xl desktop:leading-7">
                  768%
                </h5>
              </div>
              <div className="flex min-w-[82px] flex-none flex-col gap-1 text-right desktop:min-w-[127px]">
                <p className="leading-x-xs text-x-sm tablet:text-x-base tablet:leading-x-base desktop:text-x-md desktop:leading-x-md">
                  2020
                </p>
                <h5 className="text-x-md font-medium leading-x-md tablet:text-xl tablet:leading-xl desktop:text-2xl desktop:leading-7">
                  712%
                </h5>
              </div>
              <div className="flex min-w-[82px] flex-none flex-col gap-1 text-right desktop:min-w-[127px]">
                <p className="leading-x-xs text-x-sm tablet:text-x-base tablet:leading-x-base desktop:text-x-md desktop:leading-x-md">
                  <FormattedMessage
                    {...{
                      id: 'landing.chartBoard.forecast',
                      defaultMessage: 'Минимальный прогноз 2024'
                    }}
                  />
                </p>
                <h5 className="text-x-md font-medium leading-x-md tablet:text-xl tablet:leading-xl desktop:text-2xl desktop:leading-7">
                  ~ <FormattedMessage {...{ id: 'of', defaultMessage: 'от' }} />{' '}
                  300%
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden rounded-x-3xl border border-slate-300 bg-primary-600 px-[30px] py-6 tablet:p-10">
        <div className="mx-auto text-center tablet:max-w-[643px]">
          <h3 className="text-xl font-medium leading-xl text-white tablet:text-3xl">
            <FormattedMessage
              defaultMessage="Самое время для инвестиций. {br} В 2024 ожидается рост биткоина"
              id="landing.chartBoard.subtitle"
              values={{
                br: <br />
              }}
            />
          </h3>
        </div>
      </div>
    </div>
  )
}

export default LandingChart
