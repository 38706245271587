import Image from 'next/image'

import { FormattedMessage } from 'react-intl'

import Button from '@/components/Button'

const LandingBannerTwo = () => {
  return (
    <div className="relative flex flex-col gap-4 overflow-hidden rounded-x-3xl border border-slate-300 bg-primary-600 px-[30px] py-6 tablet:flex-row tablet:items-center tablet:justify-center desktop:gap-[37px] desktop:p-10">
      <div className="h-[133px] tablet:h-[148px] desktop:h-[153px]">
        <Image
          className="block h-full"
          alt="shield"
          height={138}
          priority
          src="/images/shield.svg"
          width={241}
        />
      </div>
      <div className="text-center tablet:max-w-[643px] tablet:text-left">
        <h3 className="mb-4 text-2xl font-medium leading-7 text-white tablet:mb-8 tablet:text-3xl tablet:leading-9 desktop:mb-6">
          <FormattedMessage
            defaultMessage="Пожизненная гарантия на ваше {br} активное оборудование"
            id="landing.instruction.warranty"
            values={{
              br: <br className="hidden desktop:block" />
            }}
          />
        </h3>
        <Button
          className="min-h-11 w-full !bg-white !px-8 hover:!bg-white tablet:w-fit"
          href="/shop"
          variant="secondary"
        >
          <FormattedMessage
            {...{
              id: 'button.goStore',
              defaultMessage: 'Перейти в магазин'
            }}
          />
        </Button>
      </div>
    </div>
  )
}

export default LandingBannerTwo
