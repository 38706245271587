import Link from 'next/link'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { Disclosure, Transition } from '@headlessui/react'

import { LANDING_INVEST_AMOUNT_RUB } from '@/lib/constants'

import { useLandingCurrencyConverter } from '@/hooks/landing/useLandingCurrencyConverter'

import Icon from '@/components/Icon'
import SelectArrow from '@/components/selectbox/SelectArrow'

const ACCORDION_DATA = [
  {
    id: 'one',
    title: 'Какова минимальная сумма инвестиций?',
    description: 'Вы можете купить 1 TH и инвестировать от {amount}',
    icon: 'portfolio-24'
  },
  {
    id: 'two',
    title: 'Как мне поменять заработанные BTC на рубли',
    description:
      'В нашей <link>базе знаний<link> есть подробные инструкции о том как это сделать',
    icon: 'arrows-exchange-24'
  },
  {
    id: 'three',
    title: 'Сколько предоставляется гарантия на оборудование?',
    description:
      'Наш сервис предоставляет пожизненную гарантию на все оборудование',
    icon: 'guarantee-24'
  },
  {
    id: 'four',
    title: 'Как я могу продать купленное оборудование?',
    description: 'Вы можете его продать в любой момент в личном кабинете',
    icon: 'cash-24'
  },
  {
    id: 'five',
    title: 'Как часто начисляется прибыль?',
    description:
      'Прибыль начисляется каждые сутки и вы можете выводить ее в любое время',
    icon: 'calendar-24'
  }
]

const LandingAccordion = () => {
  const { amount } = useLandingCurrencyConverter({
    amountInRUB: LANDING_INVEST_AMOUNT_RUB
  })

  return (
    <div>
      <h3 className="mb-8 text-center text-2xl font-medium leading-tight tablet:mb-10 tablet:text-3xl tablet:leading-3xl desktop:mb-15 desktop:text-4xl desktop:leading-10">
        <FormattedMessage
          {...{ id: 'landing.faq.title', defaultMessage: 'Вопросы и ответы ' }}
        />
      </h3>
      <div className="flex flex-col gap-3 tablet:gap-4 desktop:gap-5">
        {ACCORDION_DATA.map(({ title, description, icon, id }, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <div
                className={clsx(
                  'relative cursor-pointer select-none rounded-2xl border-[0.5px] border-slate-300 bg-white px-4 py-3 transition-colors tablet:px-6 tablet:py-5 desktop:py-6',
                  open ? '!border-primary-600' : ''
                )}
              >
                <Disclosure.Button className="flex w-full items-center gap-5 text-left">
                  <div className="flex h-13 w-13 flex-none items-center justify-center rounded-full bg-primary-100">
                    <Icon className="h-6 w-6 text-primary-600" name={icon} />
                  </div>
                  <h4 className="text-x-md font-medium leading-x-md">
                    <FormattedMessage
                      defaultMessage={title}
                      id={`landing.accordion.${id}.title`}
                    />
                  </h4>
                  <SelectArrow className="ml-auto flex-none" {...{ open }} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="scale-95 transform opacity-0"
                  enterTo="scale-100 transform opacity-100"
                  leave="transition duration-100 ease-out"
                  leaveFrom="scale-100 transform opacity-100"
                  leaveTo="scale-95 transform opacity-0"
                >
                  <Disclosure.Panel className="overflow-hidden">
                    <div className="pr-0 pt-2.5 desktop:max-w-[800px]">
                      <p className="text-x-base leading-x-base">
                        <FormattedMessage
                          defaultMessage={description}
                          id={`landing.accordion.${id}.text`}
                          values={{
                            amount,
                            link: (chunks) => (
                              <Link
                                className="text-blue-600 transition-colors hover:text-primary-500"
                                href="/support"
                                target="_blank"
                              >
                                {chunks}
                              </Link>
                            )
                          }}
                        />
                      </p>
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  )
}

export default LandingAccordion
