import Image from 'next/image'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import Icon from '@/components/Icon'

import LandingBannerTwo from './LandingBannerTwo'

const LandingInstruction = () => {
  const CARDS_OPTIONS = [
    {
      id: 'one',
      title: 'Выберите оборудование {br} под размер своих инвестиций',
      image: '/images/dog-database.svg'
    },
    {
      id: 'two',
      title: 'Доход будет начисляться {br} каждый день',
      image: '/images/dog-cash.svg'
    },
    {
      id: 'three',
      title: 'Выводите деньги, как удобно',
      withdraw: true,
      imageClassName: 'tablet:ml-[200px] desktop:ml-[160px]',
      image: '/images/dog-withdrawal.svg'
    },
    {
      id: 'four',
      title: 'Стоимость оборудования {br} растет вместе с курсом {br} биткоина',
      image: '/images/dog-phone-chart.svg'
    },
    {
      id: 'five',
      title:
        'Оборудование можно {br} продать в личном кабинете {br} в любой момент',
      image: '/images/dog-printing-check.svg'
    }
  ]

  return (
    <div className="space-y-[70px]">
      <div className="mx-auto grid max-w-[954px] grid-cols-1 gap-10 desktop:gap-[70px]">
        {CARDS_OPTIONS.map(
          ({ id, title, image, withdraw, imageClassName }, index) => (
            <div
              className={clsx(
                'flex flex-col items-center gap-5 rounded-2xl tablet:flex-row even:tablet:flex-row-reverse desktop:gap-3'
              )}
              key={index}
            >
              <div className="relative flex flex-auto flex-col items-center justify-center tablet:h-[211px] desktop:h-[280px]">
                <div className={clsx('h-40 tablet:h-full', imageClassName)}>
                  <Image
                    className="block h-full w-auto"
                    alt=""
                    height={160}
                    priority
                    src={image}
                    width={212}
                  />
                </div>
                {withdraw && (
                  <span
                    className={clsx(
                      'z-[-1] mt-5 flex flex-wrap justify-center gap-2 px-4 tablet:absolute tablet:bottom-0 tablet:left-0 tablet:top-0 tablet:mt-0 tablet:w-[320px] tablet:justify-start'
                    )}
                  >
                    <div className="flex items-center gap-2.5 rounded-[70px] bg-rose-100 px-3 py-2 tablet:px-6 tablet:py-2 desktop:py-2.5">
                      <Icon
                        className="table:h-6 h-5 w-5 text-rose-500 tablet:w-6"
                        name="credit-card-24"
                      />
                      <span className="text-x-sm font-medium leading-x-sm">
                        <FormattedMessage
                          defaultMessage="На банковскую карту"
                          id="landing.instruction.withdraw.card"
                        />
                      </span>
                    </div>
                    <div className="flex items-center gap-2.5 rounded-[70px] bg-green-100 px-3 py-2 tablet:px-6 tablet:py-2 desktop:py-2.5">
                      <Icon
                        className="table:h-6 h-5 w-5 text-green-500 tablet:w-6"
                        name="database-24"
                      />
                      <span className="text-x-sm font-medium leading-x-sm">
                        <FormattedMessage
                          defaultMessage="В биткоинах"
                          id="landing.instruction.withdraw.btc"
                        />
                      </span>
                    </div>
                    <div className="flex items-center gap-2.5 rounded-[70px] bg-amber-250 px-3 py-2 tablet:px-6 tablet:py-2 desktop:py-2.5">
                      <Icon
                        className="table:h-6 h-5 w-5 text-amber-500 tablet:w-6"
                        name="wallet-24"
                      />
                      <span className="text-x-sm font-medium leading-x-sm">
                        <FormattedMessage
                          defaultMessage="QIWI-кошелек"
                          id="landing.instruction.withdraw.qiwi"
                        />
                      </span>
                    </div>
                    <div className="flex items-center gap-2.5 rounded-[70px] bg-primary-200 px-3 py-2 tablet:px-6 tablet:py-2 desktop:py-2.5">
                      <Icon
                        className="table:h-6 h-5 w-5 text-primary-600 tablet:w-6"
                        name="globe-24"
                      />
                      <span className="text-x-sm font-medium leading-x-sm">
                        WebMoney
                      </span>
                    </div>
                    <div className="flex items-center gap-2.5 rounded-[70px] bg-purple-100 px-3 py-2 tablet:px-6 tablet:py-2 desktop:py-2.5">
                      <Icon
                        className="table:h-6 h-5 w-5 text-purple-400 tablet:w-6"
                        name="currency-dollar-24"
                      />
                      <span className="text-x-sm font-medium leading-x-sm">
                        <FormattedMessage
                          defaultMessage="В USDT"
                          id="landing.instruction.withdraw.usdt"
                        />
                      </span>
                    </div>
                    <div className="flex items-center gap-2.5 rounded-[70px] bg-sky-10 px-3 py-2 tablet:px-6 tablet:py-2 desktop:py-2.5">
                      <Icon
                        className="table:h-6 h-5 w-5 text-sky-500 tablet:w-6"
                        name="device-tablet-24"
                      />
                      <span className="text-x-sm font-medium leading-x-sm">
                        <FormattedMessage
                          defaultMessage="На номер телефона"
                          id="landing.instruction.withdraw.phone"
                        />
                      </span>
                    </div>
                  </span>
                )}
              </div>
              <h3 className="text-center text-x-md font-medium leading-x-md tablet:w-[40%] tablet:text-left tablet:text-2xl tablet:leading-7 desktop:w-[454px] desktop:text-3xl">
                <FormattedMessage
                  defaultMessage={title}
                  id={`landing.instruction.${id}`}
                  values={{
                    br: <br />
                  }}
                />
              </h3>
            </div>
          )
        )}
      </div>
      <LandingBannerTwo />
    </div>
  )
}

export default LandingInstruction
