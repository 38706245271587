import Image from 'next/image'

import { FormattedMessage } from 'react-intl'

import { useLandingRedirect } from '@/hooks/landing/useLandingRedirect'

import Button from '@/components/Button'

const LandingIntro = () => {
  const { handleButtonClick } = useLandingRedirect()

  return (
    <div className="mx-auto flex w-full max-w-[1040px] flex-col items-center gap-5 tablet:gap-8 desktop:flex-row-reverse desktop:justify-between desktop:gap-0">
      <div className="relative z-[1] h-[228px] flex-none tablet:h-[308px] desktop:-ml-[100px] desktop:h-[378px]">
        <Image
          className="block h-full w-auto"
          alt="Intro dog"
          height={170}
          priority
          src="/images/intro-dog-new.svg"
          width={212}
        />
      </div>
      <div className="relative z-[2] flex flex-col items-center text-center desktop:items-start desktop:text-left">
        <h3 className="mb-3 text-2xl font-medium leading-7 tablet:mb-4 tablet:text-3xl tablet:leading-x-base desktop:mb-5 desktop:text-4xl desktop:leading-[43px]">
          <FormattedMessage
            defaultMessage="Заработайте до 250% годовых {br} на облачном майнинге биткоинов"
            id="landing.intro.title"
            values={{
              br: <br />
            }}
          />
        </h3>
        <p className="mb-6 text-x-base leading-x-base tablet:max-w-[396px] tablet:text-x-md tablet:leading-[1.29] desktop:mb-8 desktop:max-w-[455px]">
          <FormattedMessage
            defaultMessage="Начните инвестировать в прозрачный и понятный бизнес с высокой маржинальностью"
            id="landing.intro.text"
          />
        </p>
        <Button
          className="mx-auto min-h-9 !px-7 !py-1.5 tablet:min-h-10 desktop:ml-0 desktop:min-h-11 desktop:!px-8"
          onClick={handleButtonClick}
          variant="secondary"
        >
          <FormattedMessage
            {...{
              id: 'button.start',
              defaultMessage: 'Начать'
            }}
          />
        </Button>
      </div>
    </div>
  )
}

export default LandingIntro
