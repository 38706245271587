import Head from 'next/head'
import React from 'react'

import LandingAccordion from '@/components/features/landing/LandingAccordion'
import LandingCalculateProfit from '@/components/features/landing/LandingCalculateProfit'
import LandingChart from '@/components/features/landing/LandingChart'
import LandingEquipment from '@/components/features/landing/LandingEquipment'
import LandingIncreaseIncome from '@/components/features/landing/LandingIncreaseIncome'
import LandingInfoCards from '@/components/features/landing/LandingInfoCrads'
import LandingInstruction from '@/components/features/landing/LandingInstruction'
import LandingIntro from '@/components/features/landing/LandingIntro'
import LandingVideo from '@/components/features/landing/LandingVideo'
import LandingDemoVersion from '@/components/features/landing/a/LandingDemoVersion'

export default function Home() {
  return (
    <div className="flex flex-col gap-10 pb-10 pt-5 tablet:pb-20 tablet:pt-4 desktop:gap-30">
      <Head>
        <title>Asterbit</title>
      </Head>
      <div className="flex flex-col gap-10">
        <LandingIntro />
        <LandingDemoVersion />
        <LandingVideo />
      </div>
      <LandingInstruction />
      <LandingChart />
      <LandingCalculateProfit />
      <LandingInfoCards />
      <LandingEquipment />
      <LandingAccordion />
      <LandingIncreaseIncome />
    </div>
  )
}
