import Image from 'next/image'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import Icon from '@/components/Icon'

const CLOUD_MINING_DATA = [
  {
    id: 'one',
    iconWrap: 'bg-primary-200 text-primary-600',
    icon: 'server-24',
    title: '5 тысяч устройств',
    text: 'Умеем работать с большими объемами'
  },
  {
    id: 'two',
    iconWrap: 'bg-green-50 text-green-500',
    icon: 'heart-24',
    title: 'Надежность и стабильность',
    text: 'Размещаем и обслуживаем оборудование клиентов по договору'
  },
  {
    id: 'three',
    iconWrap: 'bg-amber-250 text-amber-500',
    icon: 'shield-check-24',
    title: 'Бесперебойная работа',
    text: 'Гарантируем бесперебойную работу без простоев с пожизненной гарантией'
  },
  {
    id: 'four',
    iconWrap: 'bg-purple-100 text-purple-400',
    icon: 'server-24',
    title: 'Собственный ремонтный центр',
    text: 'Ремонтируем оборудование в короткие сроки'
  }
]

const LandingEquipment = () => {
  return (
    <div className="mx-auto w-full max-w-[1040px]">
      <div className="mb-8 flex flex-col items-center gap-8 tablet:mb-10 desktop:mb-15 desktop:flex-row-reverse desktop:items-end desktop:justify-between desktop:gap-10">
        <div className="h-[170px] flex-none tablet:h-[230px] desktop:h-[300px]">
          <Image
            className="block h-full w-auto"
            alt="Data dog"
            height={170}
            priority
            src="/images/big-data-dog.svg"
            width={212}
          />
        </div>
        <div className="text-center tablet:max-w-[521px] desktop:max-w-[420px] desktop:text-left">
          <h3 className="mb-4 text-2xl font-medium leading-7 tablet:text-3xl tablet:leading-x-base desktop:mb-8 desktop:text-4xl desktop:leading-[43px]">
            <FormattedMessage
              defaultMessage="Наше оборудование работает в крупнейших дата-центрах СНГ"
              id="landing.equipment.title"
            />
          </h3>
          <p className="text-x-base leading-x-base">
            <FormattedMessage
              defaultMessage="Благодаря большому объему мы можем предложить вам лучшие условия для сохранения и приумножения инвестиций"
              id="landing.equipment.text"
            />
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 tablet:grid-cols-2 tablet:gap-8 desktop:gap-[38px]">
        {CLOUD_MINING_DATA.map(({ title, text, icon, iconWrap, id }) => (
          <div
            className="flex flex-col items-center gap-4 tablet:items-start"
            key={id}
          >
            <div
              className={clsx(
                'flex h-16 w-16 flex-none items-center justify-center rounded-full desktop:h-20 desktop:w-20',
                iconWrap
              )}
            >
              <Icon className="h-8 w-8" name={icon} />
            </div>
            <div className="max-w-[400px] text-center tablet:text-left">
              <h4 className="mb-2 text-x-md font-medium leading-x-md tablet:mb-[14px] tablet:text-xl tablet:leading-xl">
                <FormattedMessage
                  defaultMessage={title}
                  id={`landing.equipment.${id}.title`}
                />
              </h4>
              <p className="text-x-base leading-x-base">
                <FormattedMessage
                  defaultMessage={text}
                  id={`landing.equipment.${id}.text`}
                />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LandingEquipment
