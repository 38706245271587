import Image from 'next/image'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { useVideo } from '@/hooks/landing/useVideo'

import Icon from '@/components/Icon'

const LandingVideo = () => {
  const { videoRef, isVideoVisible, handleVideoEnd, handleVideoClick } =
    useVideo()

  return (
    <div
      className={clsx(
        'relative flex h-[224px] items-center overflow-hidden rounded-x-3xl border-[0.5px] border-slate-100',
        'bg-sky-150 tablet:h-[400px] tablet:justify-center desktop:h-[620px]'
      )}
    >
      <video
        className={clsx(
          'h-full w-full border-none outline-none',
          isVideoVisible ? 'block' : 'hidden'
        )}
        onClick={handleVideoEnd}
        onEnded={handleVideoEnd}
        ref={videoRef}
        src="/videos/asterbit.mp4"
      />
      {!isVideoVisible && (
        <div
          className="absolute z-10 flex h-full w-full cursor-pointer select-none items-end justify-center gap-3 tablet:gap-6"
          onClick={handleVideoClick}
        >
          <div className="absolute top-1/2 flex translate-x-[-21%] translate-y-[-50%] tablet:translate-x-[-50%]">
            <div className="flex gap-3 tablet:gap-8">
              <div
                className={clsx(
                  'flex h-[50px] w-[50px] items-center justify-center rounded-full bg-primary-600',
                  'transition-colors hover:bg-primary-500 tablet:h-[85px] tablet:w-[85px] desktop:h-[102px] desktop:w-[102px]'
                )}
              >
                <Icon
                  className="h-3 w-3 text-white tablet:h-5 tablet:w-5 desktop:h-6 desktop:w-6"
                  name="play-20"
                />
              </div>
              <div className="flex flex-col">
                <h3
                  className={clsx(
                    'pb-2 text-x-md font-medium leading-[1.29] tablet:mb-[11px] tablet:text-xl',
                    'tablet:leading-xl desktop:mb-[13px] desktop:text-2xl desktop:leading-xs'
                  )}
                >
                  <FormattedMessage
                    defaultMessage="Что такое {br} облачный майнинг?"
                    id="landing.video.title"
                    values={{
                      br: <br />
                    }}
                  />
                </h3>
                <span className="text-x-base leading-x-base tablet:text-x-md tablet:leading-[1.29]">
                  <FormattedMessage
                    defaultMessage="1 м 22 сек"
                    id="landing.video.duration"
                  />
                </span>
              </div>
            </div>
          </div>
          <Image
            className="relative bottom-[-15px] right-[-32px] block h-[200px] w-full tablet:hidden"
            alt="poster"
            height={138}
            priority
            src="/images/video-poster-bg-sm.svg"
            width={241}
          />
          <Image
            className="hidden h-[200px] w-full tablet:block tablet:h-[360px] desktop:h-[555px] desktop:w-[1000px]"
            alt="poster"
            height={138}
            priority
            src="/images/video-poster-bg.svg"
            width={241}
          />
        </div>
      )}
    </div>
  )
}

export default LandingVideo
