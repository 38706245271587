import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import Icon from '@/components/Icon'

const LandingInfoCards = () => {
  const CERTIFICATES_OPTIONS = [
    {
      id: 'one',
      className: 'bg-purple-50',
      title: 'Не нужно устанавливать {br} оборудование у себя дома',
      text: 'Оборудование располагается у нас, а вы просто становитесь его владельцем',
      icon: 'server-24',
      iconClassName: 'text-purple-400',
      iconWrap: 'bg-purple-100'
    },
    {
      id: 'two',
      className: 'bg-amber-200',
      title: 'Счета за электричество оплачиваются автоматически',
      text: 'Все накладные расходы отображаются в вашем кабинете {br} и зависят от типа устройства',
      icon: 'light-bulb-24',
      iconClassName: 'text-amber-500',
      iconWrap: 'bg-amber-250'
    },
    {
      id: 'three',
      className: 'bg-green-8',
      title: 'Можно начать инвестировать {br} в майнинг с любой суммы',
      text: 'Воспользуйтесь калькулятором доходности {br} и рассчитайте свою прибыль',
      icon: 'credit-card-24',
      iconClassName: 'text-green-500',
      iconWrap: 'bg-green-50'
    },
    {
      id: 'four',
      className: 'bg-primary-50',
      title: 'Получите пассивный доход {br} уже через сутки',
      text: 'Для старта не нужны профессиональные знания, поскольку все заботы по настройке оборудования мы берем на себя',
      icon: 'clock-24',
      iconClassName: 'text-primary-600',
      iconWrap: 'bg-primary-200'
    }
  ]

  return (
    <div>
      <h3 className="mx-auto mb-8 text-center text-2xl font-medium leading-7 tablet:mb-[38px] tablet:max-w-[495px] tablet:text-3xl desktop:ml-0 desktop:text-left">
        <FormattedMessage
          defaultMessage="Облачный майнинг — это простой и доступный вид инвестиций"
          id="landing.cloudMining.title"
        />
      </h3>
      <div className="grid grid-cols-1 gap-5 tablet:gap-6 desktop:grid-cols-2 desktop:gap-10">
        {CERTIFICATES_OPTIONS.map((card, index) => (
          <div
            className={clsx(
              'flex flex-col items-center rounded-2xl p-5 text-center tablet:py-12.5 desktop:px-[54px]',
              card.className
            )}
            key={index}
          >
            <div
              className={clsx(
                'mb-4 flex h-12 w-12 flex-none items-center justify-center rounded-full tablet:h-20 tablet:w-20 desktop:mb-8',
                card.iconWrap
              )}
            >
              <Icon
                className={clsx(
                  'h-6 w-6 flex-none tablet:h-8 tablet:w-8',
                  card.iconClassName
                )}
                name={card.icon}
              />
            </div>
            <h3 className="mb-2 max-w-[419px] text-x-md font-medium leading-x-md tablet:mb-[14px] tablet:text-2xl tablet:leading-7">
              <FormattedMessage
                defaultMessage={card.title}
                id={`landing.cloudMining.${card.id}.title`}
                values={{
                  br: <br />
                }}
              />
            </h3>
            <p className="max-w-[419px] text-x-sm leading-x-sm tablet:text-x-base tablet:leading-x-base">
              <FormattedMessage
                defaultMessage={card.text}
                id={`landing.cloudMining.${card.id}.text`}
                values={{
                  br: <br />
                }}
              />
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LandingInfoCards
